import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, LookupContent, DataTableHoc } from 'smart-react';
import TestDataTableWrapper from '../DataTable/TestDataTableWrapper';
import './Lookup.scss';

const treeOptionsList = [];

/**
 * Lookup mode of Tests
 */
const LookUp = ({
  mode,
  setLookupCheckedItems,
  lookupCheckedItems,
  lookupSelectionMode,
  handleSlide,
  show,
  selectLookups,
}) => {
  const [dataSet, setDataSet] = React.useState([]);
  const [topbarSearch, setTopBarSearch] = React.useState('');
  const [selectedTree, setSelectedTree] = React.useState('locations');
  const [isSearch, setIsSearch] = React.useState('');
  const [isFilterFavourite, setIsFilterFavourite] = React.useState(false);
  const [isRecent, setIsRecent] = React.useState(false);
  const [treeDataSet, setTreeDataSet] = React.useState([]);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <LookupContent
        dataSet={dataSet}
        setDataSet={setDataSet}
        treeDataSet={treeDataSet}
        treeOptionsList={treeOptionsList}
        setTopBarSearch={setTopBarSearch}
        topbarSearch={topbarSearch}
        setSelectedTree={setSelectedTree}
        selectedTree={selectedTree}
        setIsSearch={setIsSearch}
        isSearch={isSearch}
        setIsFilterFavourite={setIsFilterFavourite}
        isFilterFavourite={isFilterFavourite}
        isRecent={isRecent}
        setIsRecent={setIsRecent}
        LookupDataTable={TestDataTableWrapper}
        mode={mode}
        selectLookups={selectLookups}
        setLookupCheckedItems={setLookupCheckedItems}
        lookupCheckedItems={lookupCheckedItems}
        lookupSelectionMode={lookupSelectionMode}
        handleEquipmentSlide={handleSlide}
        show={show}
        searchPlaceHolder={'Search Tests'}
        isTreeSidebar={false}
        isFavouriteEnable={false}
        isRecentEnable={false}
        isSearchEnable={false}
      />
    </ErrorBoundary>
  );
};

export default LookUp;
