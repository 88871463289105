import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import { Loader } from 'smart-react';
import {
  AUTEST_TEST_GITHUB_RAW_URL,
  AUTEST_TEST_GITHUB_REPO_URL,
} from '../../../../../constants/applicationConstants';
import './TestDoc.scss';

/**
 * Converts relative image URLs in Markdown to absolute URLs based on the base URL.
 * @param {string} markdown - The raw Markdown content.
 * @param {string} baseUrl - The base URL for the GitHub repository.
 * @returns {string} - The processed Markdown with absolute image URLs.
 */
const convertRelativeImageUrlsToAbsolute = (markdown, baseUrl) => {
  const imageUrlRegex = /!\[([^\]]*)\]\((?!https?:\/\/)([^)]+)\)/g;
  return markdown.replace(imageUrlRegex, (match, altText, relativeUrl) => {
    const absoluteUrl = `${baseUrl}/${relativeUrl}`;
    return `![${altText}](${absoluteUrl})`;
  });
};

/**
 * Converts relative anchor URLs in HTML to absolute URLs based on the GitHub repository URL.
 * @param {string} html - The HTML content.
 * @param {string} repoUrl - The base URL for the GitHub repository.
 * @returns {string} - The processed HTML with absolute anchor URLs.
 */
const convertRelativeAnchorUrlsToAbsolute = (html, repoUrl) => {
  const anchorUrlRegex = /<a\s+href="(?!https?:\/\/)([^"]+)">/g;
  return html.replace(anchorUrlRegex, (match, relativeUrl) => {
    // Remove './tests_docs/' prefix from the relative URL if it exists
    const cleanedRelativeUrl = relativeUrl.replace(/^\.\/tests_docs\//, '');
    const absoluteUrl = `${repoUrl}/${cleanedRelativeUrl}`;
    return match.replace(relativeUrl, absoluteUrl);
  });
};

/**
 * Process Add Argument Module
 * @param {*} props props passed to component
 * @returns {JSX.Element} The TestDoc view.
 */
const TestDoc = (props) => {
  const { test } = props;
  const [fileContent, setFileContent] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFileContent = async () => {
      try {
        const response = await fetch(
          `${AUTEST_TEST_GITHUB_RAW_URL}/${test.uc_ossi_app_test_id}.md`,
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const text = await response.text();
        const processedMarkdown = convertRelativeImageUrlsToAbsolute(
          text,
          AUTEST_TEST_GITHUB_RAW_URL,
        );
        let htmlContent = marked(processedMarkdown);
        htmlContent = convertRelativeAnchorUrlsToAbsolute(
          htmlContent,
          AUTEST_TEST_GITHUB_REPO_URL,
        );
        setFileContent(htmlContent);
      } catch (error) {
        setFileContent(
          `<h1 className="!k-my-5 k-text-center">No Documentation Found!</h1>`,
        );
      } finally {
        setLoading(false);
      }
    };

    if (test?.uc_ossi_app_test_id) {
      fetchFileContent();
    }
  }, [test]);

  return (
    <>
      {loading && <Loader />}
      <div dangerouslySetInnerHTML={{ __html: fileContent }} />
    </>
  );
};

export default TestDoc;
