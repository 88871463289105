import * as React from 'react';
import {
  Card,
  CardBody,
  TabStrip,
  TabStripTab,
} from '@progress/kendo-react-layout';
import TestArguments from '../../../TestArguments/Pages/TestArguments';
import TestSteps from '../../../TestSteps/Pages/TestSteps';
import { isComponentUpdate } from 'smart-react';
/**
 * Test Details Desktop view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The DesktopView component.
 */
const DesktopView = ({
  selected,
  handleSelect,
  detailsDataModified,
  state,
}) => {
  return (
    <>
      <Card>
        <TabStrip
          className='page-tabs'
          selected={selected}
          onSelect={handleSelect}
        >
          <TabStripTab
            title='Argument'
            contentClassName={`tabs-card ${
              detailsDataModified === true ? 'tab-modified' : ''
            }`}
          >
            <Card
              className={`tabs-card ${
                detailsDataModified === true ? 'tab-modified' : ''
              }`}
            >
              <CardBody>
                <div className='card-body '>
                  <TestArguments test={state.dataSet} />
                </div>
              </CardBody>
            </Card>
          </TabStripTab>
          <TabStripTab
            title='Steps'
            contentClassName={`tabs-card ${
              detailsDataModified === true ? 'tab-modified' : ''
            }`}
          >
            <Card
              className={`tabs-card ${
                detailsDataModified === true ? 'tab-modified' : ''
              }`}
            >
              <CardBody>
                <div className='card-body '>
                  <TestSteps test={state.dataSet} />
                </div>
              </CardBody>
            </Card>
          </TabStripTab>
        </TabStrip>
      </Card>
    </>
  );
};

// Export the memoized component
export default React.memo(DesktopView, isComponentUpdate);
