import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Loader, ErrorFallback, DropDownInput } from 'smart-react';
import { Button } from '@progress/kendo-react-buttons';
import LookUpSlider from '../Lookup/LookupSlider';
import { useNavigate } from 'react-router-dom';
import {
  TESTS,
  CONTAINS_OP,
} from '../../../../../constants/applicationConstants';
import {
  LookUp_Mode,
  Lookup_Selection_Mode,
} from '../../../../../constants/applicationConstants';
import { useAuth } from '../../../../Core/Context/AuthContext';
import { listTest } from '../../Services/TestsService';

/**
 * Test Input Component with Lookup Integration
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Test Input Component.
 */
const TestInput = ({
  currentTest,
  setCurrentTest,
  lookupSelectionMode,
  disabled,
  fieldWrapperStyles,
  requiredField,
}) => {
  const navigate = useNavigate();
  const { getTokensFromStorage } = useAuth(); // Get tenantID using Auth Context

  const [testSlideShow, setTestSlideShow] = React.useState(false);
  const [selectedLookupItems, setSelectedLookupItems] = React.useState([]);
  /**
   * Used to show/hide test lookup slide
   */
  const handleTestSlide = () => {
    if (testSlideShow) {
      setTestSlideShow(false);
    } else {
      setTestSlideShow(true);
    }
  };
  const [loading, setLoading] = React.useState(false);
  const [testDropdownData, setTestDropdownData] = React.useState([]);

  /**
   * handle dropdown Change
   * @e object
   */
  const handleDropdownChange = (e) => {
    setCurrentTest(e.value);
  };

  /**
   * fetch Tests
   * @filter {Array}
   */
  const fetchTests = async (filter) => {
    const { accessToken } = getTokensFromStorage();
    setLoading(true);
    const response = await listTest({
      accessToken: accessToken,
      limit: '50',
      offset: 1,
      filter: filter ? filter : [],
      moduleName: TESTS,
      isServiceWorker: false,
    });
    setLoading(false);
    return response.tests;
  };

  React.useEffect(() => {
    (async () => {
      const tests = await fetchTests('');
      setTestDropdownData(tests);
    })();
  }, []);

  /**
   * filter Change
   * @event object
   */
  const filterChange = async (event) => {
    let filter = [
      {
        ColumnName: 'uc_ossi_app_test_id',
        Op: CONTAINS_OP,
        ColumnValue: event.filter.value,
      },
    ];
    const tests = await fetchTests(filter);
    setTestDropdownData(tests);
  };

  React.useEffect(() => {
    if (lookupSelectionMode === Lookup_Selection_Mode?.single) {
      if (selectedLookupItems?.length > 0) {
        const dataItem = {
          uc_ossi_app_test_id: selectedLookupItems[0].uc_ossi_app_test_id,
        };
        setCurrentTest({ ...dataItem });
      }
    }
  }, [selectedLookupItems]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          {loading && <Loader />}
          <div className='runset-detail'>
            <div>
              <span className='k-floating-label-container k-label'>
                <label for='uc_ossi_app_test_id' className='k-label'>
                  Test{' '}
                  {requiredField && <span className='required-field'>*</span>}
                </label>
              </span>
              <div className='test-dropdown-wrapper' style={fieldWrapperStyles}>
                <div className='dropdown-wrapper'>
                  <DropDownInput
                    textField={'uc_ossi_app_test_id'}
                    dataItemKey={'uc_ossi_app_test_id'}
                    className=' tenant-dropdown k-align-self-end'
                    dataSet={testDropdownData}
                    value={currentTest}
                    defaultItem={{
                      uc_ossi_app_test_id: 'Select Test ...',
                      disabled: true,
                    }}
                    skipDisabledItems={false}
                    disabled={disabled}
                    requiredField={'true'}
                    handleChange={handleDropdownChange}
                    onFilterChange={filterChange}
                    filterable={true}
                  />
                </div>
                <div>
                  <Button
                    themeColor={'primary'}
                    className='k-mx-1'
                    icon='search'
                    onClick={() => setTestSlideShow(true)}
                  ></Button>
                </div>
              </div>
            </div>

            <LookUpSlider
              show={testSlideShow}
              handleSlide={handleTestSlide}
              setSelectedLookupItems={setSelectedLookupItems}
              selectedLookupItems={selectedLookupItems}
              lookupSelectionMode={lookupSelectionMode}
              mode={LookUp_Mode.LookUp}
            />
          </div>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default TestInput;
